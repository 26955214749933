import cx from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, GatsbyImageProps, StaticImage } from "gatsby-plugin-image";
import React from "react";
import { useCookies } from "react-cookie";
import { BlockWithVideo } from "../components/Block";
import { useCommonTranslations } from "../components/CommonTranslations";
import Layout from "../components/layout";
import { ScrollIntoView } from "../components/ScrollIntoView";
import Seo from "../components/seo";
import { useFilteredByLocale } from "../hooks";
import { ActorSocials } from "./cast";

export default function DirectorsVision({ location }: { location: Location }) {
  const commonTranslations = useCommonTranslations();

  return (
    <Layout location={location} headerProps={{ fixed: true, filled: true, logo: true }}>
      <Seo title={commonTranslations.directorsVision} />
      <Body />
    </Layout>
  );
}

const Body = () => {
  const {
    title,
    blocks,
    images,
    byPeeterRebane,
    directorImage,
    directorParagraphs,
    directorTitle,
    directorSocials,
    directorName,
  } = useDirectorsVision();

  return (
    <div className="w-full text-white min-h-screen relative">
      <StaticImage
        className="object-cover h-screen w-screen"
        src="../images/directors-vision-banner.png"
        alt="Director's vision"
        style={{ position: "absolute", top: 0 }}
      />
      <div className="grid grid-cols-12 relative mb-14 overflow-hidden md:gap-2">
        <div className="col-span-10 col-start-2 text-white mt-[60vh] mb-4">
          <h1 className="text-3xl md:ml-10">
            <span className="font-bold">{title}</span> <span className="font-light">{byPeeterRebane}</span>
          </h1>
        </div>
        <div className="col-span-10 col-start-2 md:col-span-7 md:col-start-2 lg:col-span-6 lg:col-start-2 bg-black rounded-lg p-4 md:p-10 flex flex-col space-y-8">
          {blocks.map((props, i) => (
            <BlockWithVideo key={i} {...props} />
          ))}
        </div>
        <div className="hidden md:col-span-3 md:flex lg:col-start-9 flex-col justify-between">
          {images?.map((url, i) => (
            <ScrollIntoView
              className={cx("flex h-full w-full items-center", {
                "justify-start": i % 2 !== 1,
                "justify-end": i % 2 === 1,
              })}
              key={i}
              persist
            >
              <GatsbyImage image={url} alt="" className="md:w-5/6 xl:w-2/3" />
            </ScrollIntoView>
          ))}
        </div>
        <div className="col-span-10 col-start-2 md:col-span-7 md:col-start-2 lg:col-span-6 lg:col-start-2 bg-black rounded-lg p-4 md:p-8 flex flex-col space-y-8">
          <h1 className="text-3xl font-bold mb-8">
            <span className="font-bold">{directorTitle}</span> <span className="font-light">{directorName}</span>
          </h1>
          {directorParagraphs.map(({ text }, i) => (
            <p key={i}>{text}</p>
          ))}
        </div>
        <div className="flex col-span-10 col-start-2 items-center md:items-start md:col-span-3 md:flex lg:col-start-9 flex-col">
          <GatsbyImage image={directorImage} alt={directorTitle} className="md:w-5/6 mt-8 mb-4 w-1/2" />
          <ActorSocials socials={directorSocials} />
        </div>
      </div>
    </div>
  );
};

const useDirectorsVision = (): Omit<DirectorsVision, "directorImage" | "images"> & {
  directorImage: GatsbyImageProps["image"];
  images: GatsbyImageProps["image"][] | null;
} => {
  const {
    allStrapiDirectorsVision: { edges },
  } = useStaticQuery<{ allStrapiDirectorsVision: EdgesQuery<{ node: DirectorsVision }> }>(query);

  return useFilteredByLocale(
    edges.map(({ node }) => ({
      ...node,
      directorImage: node.directorImage?.localFile?.childImageSharp.gatsbyImageData,
      images: node.images.map(
        ({
          localFile: {
            childImageSharp: { gatsbyImageData },
          },
        }) => gatsbyImageData
      ),
    }))
  )[0];
};

const query = graphql`
  query DirectorsVision {
    allStrapiDirectorsVision {
      edges {
        node {
          title
          byPeeterRebane
          locale
          blocks {
            title
            body
            videoUrl
          }
          directorTitle
          directorName
          directorParagraphs {
            text
          }
          directorSocials {
            imdb
          }
          directorImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
          images {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  }
`;

/* directorSocials {
            facebook
            instagram
            twitter
          } */
